import { useEffect } from 'react';

export const ZohoChat = () => {
    useEffect(() => {
        const $zoho = window.$zoho || {};
        window.$zoho = $zoho;
        $zoho.salesiq = $zoho.salesiq || { widgetcode: 'siq65ae746de5c54b08fa5b5ff13789615bb5c56048954ed45b4dfba408da8023b4', values: {}, ready: function() {} };

        const d = document;
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zohopublic.com/widget';
        const t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }, []);

    return null;
};

