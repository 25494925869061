import React, { useEffect, useState , useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from '../../globalStyles';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
    Nav,
    MobileIcon,
    NavbarContainer,
    NavLogo,
    NavIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavItemMobile,
    NavItemBtn,
    NavSubMenuContainer,
    NavSubMenu,
    NavSubMenuItem,
    NavSubMenuContentImg,
    NavSubMenuContentTitle,
    NavSubMenuContentSubTitle,
    ArrowImgMobile
} from './NavbarStyles';
import drillBitLogo from './drillBitLogo.svg';
// import drillBitWhiteLogo from './drillBitWhiteLogo.svg';

const Navbar = (props) => {
    const {
        navBarData,
        arrowData,
        ServiceSubMenu
    } = props;
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showServiceSubMenu, setShowServiceSubMenu] = useState(false);
    const [prodType, setProdType] = useState('products');
    const [prodSupport, setProdSupport] = useState('support');

    const [background, setBackground] = useState(false);
    const [color, setColor] = useState(false);
    const [logo, setLogo] = useState(false);
    const popoverRef = useRef(null);
    const productpopoverRef = useRef(null);


    const closeMobileMenu = (e) => {
        if (e.target.innerText === 'Products\nOrganizationInstitutesPersonal') {
            setShowSubMenu(!showSubMenu);
            navigate('/organization');
            setShowServiceSubMenu(false);
        }
        if (e.target.innerText === 'Products') {
            setShowSubMenu(!showSubMenu);
            setShowServiceSubMenu(false);
            setProdType('products');
        }
        if (e.target.innerText === 'Support\nFAQsOpen APIsUser Guide') {
            setShowServiceSubMenu(!showServiceSubMenu);
            navigate('/userGuides');
            setShowSubMenu(false);
        }
        if (e.target.innerText === 'Support') {
            setShowServiceSubMenu(!showServiceSubMenu);
            setShowSubMenu(false);
            setProdSupport('support');
        }
        if (e.target.innerText === 'Integration') {
            setShowServiceSubMenu(false);
            setShowSubMenu(false);
            navigate('/integration');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (e.target.innerText === 'Contact') {
            setShowServiceSubMenu(false);
            setShowSubMenu(false);
            navigate('/contactus');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (e.target.innerText === 'Home') {
            setShowServiceSubMenu(false);
            setShowSubMenu(false);
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        setShow(false);
    };


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 80) {
                setBackground(true);
                setColor(true);
                setLogo(true);
                return;
            }
            setBackground(false);
            setColor(false);
            setLogo(false);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigateTo = (link) => {
        navigate('/' + link);
        setShowSubMenu(false);
        setShowServiceSubMenu(!showServiceSubMenu);
        setShowServiceSubMenu(false);
    };

    const navigateToSub = (link) => {
        if (link.menu === 'products') {
            setShowSubMenu(!showSubMenu);
            setShowServiceSubMenu(false);
        }
        if (link.menu === 'support') {
            setShowServiceSubMenu(!showServiceSubMenu);
            setShowSubMenu(false);
        }
    };

    // const handleClickOutside = (event) => {
    //     if (popoverRef.current && !popoverRef.current.contains(event.target)) {
    //         setShowSubMenu(false);
    //     }
    //     if (productpopoverRef.current && !productpopoverRef.current.contains(event.target)) {
    //         setShowServiceSubMenu(false);
    //     }
    // };
      
    // useEffect(() => {
    //     // Attach event listener on mount
    //     document.addEventListener('mousedown', handleClickOutside);
        
    //     // Clean up the event listener when component unmounts
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        <>
            <Nav background={background}>
                <NavbarContainer>
                    <NavLogo background={background} to="/" onClick={scroll.scrollToTop}>
                        <NavIcon src={logo ? drillBitLogo : drillBitLogo} alt="DrillBit" />
                    </NavLogo>
                    <MobileIcon background={background} onClick={() => setShow(!show)}>
                        {show ? <FaTimes /> : <FaBars />}
                    </MobileIcon>
                    <NavMenu background={background} onClick={() => setShow(!show)} show={show}>
                        {arrowData.map((navItem, index) => (
                            <NavItem key={index}>
                                <NavLinks
                                    color={color}
                                    spy={true}
                                    duration={500}
                                    smooth={true}
                                    exact="true"
                                    offset={-80}
                                    onClick={closeMobileMenu}
                                >
                                    {t(navItem.menu)}
                                    <NavItemMobile>
                                        {navItem.subMenu?.length > 0 && navItem.subMenu.map((itm, index)=>(
                                            <span style={{marginLeft:'10px'}} key={index}>
                                                <span onClick={(e)=>  {e.stopPropagation();navigate(itm.link);setShow(!show);}}>{itm.name}</span>
                                            </span>
                                        ))}
                                    </NavItemMobile>
                                  
                                    {(prodType === navItem.menu) ?
                                        <ArrowImgMobile onClick={() => navigateToSub(navItem)}
                                            src={showSubMenu && (prodType === navItem.menu) ? './images/navSubMenuIcon/arrowUp.svg' :
                                                './images/navSubMenuIcon/arrowDown.svg'} />
                                        : ''}
                                    {(prodSupport === navItem.menu) ?
                                        <ArrowImgMobile onClick={() => navigateToSub(navItem)}
                                            src={showServiceSubMenu && (prodSupport === navItem.menu) ? './images/navSubMenuIcon/arrowUp.svg' :
                                                './images/navSubMenuIcon/arrowDown.svg'} />
                                        : ''}
                                </NavLinks>
                            </NavItem>
                        ))}
                        <NavItemBtn>
                            <a href="https://www.drillbitplagiarismcheck.com/auth/login" target='_blank' rel="noreferrer">
                                <Button onClick={closeMobileMenu} primary>
                                    {t('buttonLabel')}
                                </Button>
                            </a>
                        </NavItemBtn>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
            {showSubMenu ?
                <NavSubMenuContainer background={background} ref={popoverRef}>
                    <NavSubMenu>
                        {navBarData.map((navItem, index) => (
                            <NavSubMenuItem key={index} onClick={() => navigateTo(navItem.link)}>
                                <div style={{ width: '30%' }}>
                                    <NavSubMenuContentImg src={navItem.icon} alt="DrillBit" />
                                </div>
                                <div style={{ width: '70%' }}>
                                    <NavSubMenuContentTitle>{navItem.title}</NavSubMenuContentTitle>
                                    <NavSubMenuContentSubTitle>{navItem.subTitle}</NavSubMenuContentSubTitle>
                                </div>
                            </NavSubMenuItem>
                        ))}
                        <>
                            <span onClick={()=>setShowSubMenu(!showSubMenu)} style={{position:'absolute', top:'10px',right:'15px', cursor:'pointer'}}>
                                <CloseIcon />
                            </span>
                        </>
                    </NavSubMenu>
                </NavSubMenuContainer> : ''}
            {showServiceSubMenu ?
                <NavSubMenuContainer background={background} ref={productpopoverRef}>
                    <NavSubMenu>
                        {ServiceSubMenu.map((navItem, index) => (
                            <NavSubMenuItem key={index} onClick={() => navigateTo(navItem.link)}>
                                <div style={{ width: '30%' }}>
                                    <NavSubMenuContentImg src={navItem.icon} alt="DrillBit" />
                                </div>
                                <div style={{ width: '70%' }}>
                                    <NavSubMenuContentTitle>{navItem.title}</NavSubMenuContentTitle>
                                    <NavSubMenuContentSubTitle>{navItem.subTitle}</NavSubMenuContentSubTitle>
                                </div>
                            </NavSubMenuItem>
                        ))}
                    </NavSubMenu>
                    <>
                        <span onClick={()=>setShowServiceSubMenu(!showServiceSubMenu)} style={{position:'absolute', top:'10px',right:'15px', cursor:'pointer'}}>
                            <CloseIcon />
                        </span>
                    </>
                </NavSubMenuContainer> : ''}
        </>
    );
};

Navbar.propTypes = {
    navBarData: PropTypes.array,
    arrowData: PropTypes.array,
    ServiceSubMenu: PropTypes.array,
};

export default Navbar;